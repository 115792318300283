const sk = 'localStorage'

const storage = (typeof window !== 'undefined' && window[sk]) || undefined

let errored = false

export function get(key: string) {
  try {
    return storage?.getItem(key) ?? undefined
  } catch (e) {
    errored || console.error(`${sk}.getItem failed`, e)
    errored = true
  }
}

export function put(key: string, val: string) {
  try {
    storage?.setItem(key, val)
  } catch (e) {
    errored || console.error(`${sk}.setItem failed`, e)
    errored = true
  }
  return val
}

export function remove(key: string) {
  try {
    return storage?.removeItem(key)
  } catch (e) {
    errored || console.error(`${sk}.removeItem failed`, e)
    errored = true
  }
}
