import hoistNonReactStatics from 'hoist-non-react-statics'
import {ComponentType, forwardRef} from 'react'
import {kebabCase, truish} from 'tizra'
import {KebabCase} from 'type-fest'

/**
 * Convert from pixels to rems.
 *
 * This is always based on 16px even if the base font size changes, since the
 * purpose is to make consistent rems from familiar pixel values. The resulting
 * values will scale properly just as if we had used rems directly.
 */
export const toRem = (px: number) => `${px / 16}rem`

/**
 * Convert from ems/rems to pixels.
 */
export const toPx = (s: string | number) =>
  typeof s !== 'string' ? s
  : s.endsWith('em') ? Math.round(parseFloat(s) * 16)
  : parseInt(s)

/**
 * Convert an object to transient props, e.g. {k:v, a:b} => {$k:v, $a:b}
 */
export const transient = <P extends object>(props: P) =>
  Object.fromEntries(Object.entries(props).map(([k, v]) => [`$${k}`, v])) as {
    [K in keyof P as K extends string ? `$${K}` : K]: P[K]
  }

/**
 * Convert an object to data props with kebab-case,
 * e.g. {k:v, andAlso:b} => {'data-k': v, 'data-and-also': b}
 */
export const dataProps = <P extends object>(props: P) =>
  Object.fromEntries(
    Object.entries(props)
      .filter(([_k, v]) => truish(v))
      .map(([k, v]) => [`data-${kebabCase(k)}`, v]),
  ) as {
    [K in keyof P as K extends string ? `data-${KebabCase<K>}` : K]: P[K]
  }

/**
 * HOC to add default props.
 */
export const withProps =
  <D extends object>(defaults: D) =>
  <P extends Partial<D>>(Comp: ComponentType<P>) => {
    const WithProps = forwardRef<
      any,
      Omit<P, keyof D> & Partial<Pick<P, keyof D>>
    >((props, ref) => <Comp {...defaults} {...(props as P)} ref={ref} />)
    WithProps.displayName = `withProps(${Comp.displayName || Comp.name || 'Unknown'})`
    hoistNonReactStatics(WithProps, Comp)
    return WithProps
  }

/**
 * HOC to drop theme for Linaria-wrapped styled component.
 */
export const dropTheme = <P extends object>(Comp: ComponentType<P>) => {
  const DropTheme = forwardRef<any, P>(({theme, ...props}: any, ref) => (
    <Comp {...props} ref={ref} />
  ))
  DropTheme.displayName = `dropTheme(${Comp.displayName || Comp.name || 'Unknown'})`
  hoistNonReactStatics(DropTheme, Comp)
  return DropTheme
}
