import * as R from 'rambdax'
import {CamelCasedProperties, KebabCasedProperties, Promisable} from 'type-fest'
import {tizraCamelize, tizraKebabize} from './camel'
import {flattenReaderToc} from './helpers'
import {
  AdminType,
  Attachment,
  AuthInfo,
  CartItem,
  Excerpt,
  GenericMetaObject,
  Licenses,
  MetaObject,
  NestedTocEntry,
  OEmbed,
  Offer,
  PageInfo,
  Paged,
  PubInfo,
  Redemption,
  SearchResult,
  SearchTypes,
  TocEntry,
  User,
} from './types'
import {QSPairs, ensureArray} from './utils'

export interface Info {
  path: null | string | ((params: any) => string)
  isOk: (props: {
    contentType: string
    params: any
    data: any
    text: string | undefined
    response: Response
    status: number
  }) => Promisable<boolean>
  method: 'DELETE' | 'GET' | 'POST' | 'PUT'
  paramsFn: (...args: any[]) => QSPairs
  dataFn: (props: {
    contentType: string
    params: any
    data: any
    text: string | undefined
    response: Response
    status: number
  }) => Promisable<unknown>
}

interface IdParams {
  tizraId: string
}

interface FieldsParams {
  fields?: string[]
  props?: string[]
}

interface PagingParams {
  start?: number
  limit?: number
  page?: number
}

interface SortPropParam {
  sortProp?: string
}

interface SearchQueryParams {
  any?: string[]
  all?: string[]
  excluded?: string[]
  metaType?: string
  filterCollectionId?: string
  filterTitleGroup?: string
  filterLicenses?: true
}

interface SearchParams
  extends FieldsParams,
    PagingParams,
    SortPropParam,
    SearchQueryParams {
  invertOrder?: true
  snippetProp?: string
  snippetQuery?: string
}

interface PropValuesParams extends SearchQueryParams {
  propNames: string[]
  prefix?: string
  limit?: number
}

interface ReasonableError {
  reason: string
  message: string
}

export interface FormErrors extends Partial<ReasonableError> {
  errors?: {
    [k: string]: ReasonableError
  }
}

type KebabXorCamelCasedProperties<T> =
  | KebabCasedProperties<T>
  | CamelCasedProperties<T>

const info = <
  Raw,
  Cooked,
  Params = undefined,
  Path extends null | string | ((params: Params) => string) =
    | string
    | ((params: Params) => string),
>({
  method = 'GET',
  paramsFn = (params: Params) =>
    (params ? tizraKebabize(params) : []) as QSPairs,
  isOk = ({
    contentType,
    data,
    status,
  }: {
    contentType: string
    data: any
    status: number
  }) =>
    status >= 200 &&
    status < 300 &&
    (data !== undefined || contentType !== 'application/json'),
  dataFn = ({data}: {data: Raw}) => tizraCamelize(data) as Cooked,
  ...info
}: {
  path: Path
  method?: Info['method']
  paramsFn?: (params: Params) => QSPairs
  isOk?: (props: {
    contentType: string
    params: Params
    data: Raw
    response: Response
    status: number
  }) => Promisable<boolean>
  dataFn?: (props: {
    contentType: string
    params: Params
    data: Raw
    response: Response
    text: string | undefined
    status: number
  }) => Promisable<Cooked>
}) =>
  ({
    method,
    paramsFn,
    isOk,
    dataFn,
    ...info,
  }) as const

export const infos = {
  DELETE: info<
    unknown,
    {data: unknown; response: Response; status: number},
    QSPairs | undefined,
    null
  >({
    method: 'DELETE',
    path: null,
    paramsFn: params => params || [],
    isOk: ({status}) => status !== 500,
    dataFn: ({contentType, data, response, status, text}) => ({
      contentType,
      data,
      response,
      status,
      text,
    }),
  }),

  GET: info<
    unknown,
    {
      contentType: string
      data: unknown
      response: Response
      status: number
      text?: string
    },
    QSPairs | undefined,
    null
  >({
    method: 'GET',
    path: null,
    paramsFn: params => params || [],
    isOk: ({status}) => status !== 500,
    dataFn: ({contentType, data, response, status, text}) => ({
      contentType,
      data,
      response,
      status,
      text,
    }),
  }),

  POST: info<
    unknown,
    {data: unknown; response: Response; status: number; text?: string},
    any,
    null
  >({
    method: 'POST',
    path: null,
    paramsFn: R.identity,
    isOk: ({status}) => status !== 500,
    dataFn: ({contentType, data, response, status, text}) => ({
      contentType,
      data,
      response,
      status,
      text,
    }),
  }),

  PUT: info<
    unknown,
    {data: unknown; response: Response; status: number},
    any,
    null
  >({
    method: 'PUT',
    path: null,
    paramsFn: R.identity,
    isOk: ({status}) => status !== 500,
    dataFn: ({contentType, data, response, status, text}) => ({
      contentType,
      data,
      response,
      status,
      text,
    }),
  }),

  attachments: info<
    Paged<any>,
    Paged<Attachment>,
    IdParams & PagingParams & {filterLicenses?: boolean}
  >({
    path: 'query/attachments',
    dataFn: ({data}) => {
      const parseBoolProp = (k: string, o: Record<string, unknown>) => {
        const v = o[k]
        return typeof v === 'string' && {[k]: v === 'true'}
      }
      return R.piped(
        data,
        R.over(
          R.lensProp('result'),
          // API doesn't return parsed-props, so fake it
          R.map(({props, ...item}: any) => ({
            ...item,
            'parsed-props': {
              ...props,
              ...parseBoolProp('includeDirectly', props),
              ...parseBoolProp('isDownload', props),
              ...parseBoolProp('isUrlName', props),
              ...parseBoolProp('isVisible', props),
            },
          })),
        ),
        // Now it's safe to call tizraCamelize wihout losing information due to
        // tizraCamelize dropping props.
        tizraCamelize,
      ) as Paged<Attachment>
    },
  }),

  canAccess: info<unknown, AuthInfo, IdParams>({
    path: 'auth/can-access',
  }),

  cart: info<unknown, CartItem[]>({
    path: 'cart',
  }),

  cartAdd: info<
    unknown,
    unknown,
    {objectId: string; offerId: string; quantity?: number}
  >({
    method: 'POST',
    path: 'cart',
  }),

  cartRemove: info<unknown, unknown, {itemId: string}>({
    method: 'DELETE',
    path: ({itemId}) => `cart/${itemId}`,
    paramsFn: ({itemId, ...rest}) => tizraKebabize(rest),
  }),

  cartSize: info<unknown, number>({
    path: 'cart/size',
  }),

  changePassword: info<
    FormErrors,
    FormErrors & {status: number},
    {username: string; password: string}
  >({
    method: 'POST',
    path: 'change-password',
    paramsFn: R.identity, // no kebab
    isOk: ({status}) => status !== 500,
    dataFn: ({data, status}) => ({...data, status}),
  }),

  collections: info<
    unknown,
    Paged<GenericMetaObject>,
    FieldsParams & PagingParams & SortPropParam
  >({
    path: 'query/collections',
  }),

  contents: info<
    unknown,
    Paged<GenericMetaObject>,
    IdParams & FieldsParams & PagingParams & SortPropParam
  >({
    path: 'query/contents',
  }),

  excerpts: info<
    unknown,
    Paged<Excerpt>,
    IdParams & FieldsParams & PagingParams
  >({
    path: 'query/excerpts',
  }),

  // Accept ?_=whatever as react-query cache-buster
  licenses: info<unknown, Licenses, {_?: string}>({
    path: 'query/licenses',
  }),

  list: info<
    unknown,
    Paged<GenericMetaObject>,
    {metaType: string[]} & FieldsParams & PagingParams
  >({
    path: 'query/',
  }),

  loggedInUsers: info<any, User[], {filterSuper?: boolean}>({
    path: 'query/logged-in-users',
    dataFn: ({data}) => tizraCamelize(data.result),
  }),

  logicalPages: info<unknown, string[], IdParams>({
    path: 'logical-pages',
  }),

  login: info<
    FormErrors,
    FormErrors & {status: number},
    {username: string; password: string}
  >({
    method: 'POST',
    path: 'login',
    isOk: ({status}) => status !== 500,
    paramsFn: R.identity, // no kebab
    dataFn: ({data, status}) => ({...data, status}),
  }),

  oEmbedProxy: info<OEmbed, OEmbed | null, IdParams & {width?: number}>({
    path: 'query/oembed-proxy',
    isOk: ({status}) => status !== 500,
    dataFn: ({data, status}) => (status === 200 ? data : null),
  }),

  overlappingExcerpts: info<
    Record<string, any>, // {"42": [Excerpt...]}
    Excerpt[],
    {pageNumber: number} & IdParams & FieldsParams
  >({
    path: 'overlapping-excerpts',
    paramsFn: params => ({
      fields: ['pages', 'parsed-props', 'tizra-id'],
      ...tizraKebabize(params),
    }),
    dataFn: ({data}) => {
      const excerpts = tizraCamelize(
        Object.values(data).flat(),
      ) as Array<Excerpt>
      // prettier-ignore
      excerpts.sort(
        (a, b) => (a.pages[0] < b.pages[0] ? -1 : // earlier
        a.pages[0] > b.pages[0] ? 1 :
        a.pages[1] > b.pages[1] ? -1 : // larger
        a.pages[1] < b.pages[1] ? 1 : 0),
      )
      return excerpts
    },
  }),

  passwordReset: info<
    FormErrors,
    FormErrors & {status: number},
    {email: string; successUrl: string}
  >({
    method: 'POST',
    path: 'password-reset',
    paramsFn: R.identity, // no kebab
    isOk: ({status}) => status !== 500,
    dataFn: ({data, status}) => ({...data, status}),
  }),

  propValues: info<
    string[] | Record<string, string[]>,
    Record<string, string[]>,
    PropValuesParams
  >({
    method: 'POST',
    path: 'prop-values',
    dataFn: ({data, params: {propNames}}) =>
      Array.isArray(data) ? {[propNames[0]]: data} : data,
  }),

  propValuesInfo: info<
    | Array<{count: number; value: string}>
    | Record<string, Array<{count: number; value: string}>>,
    Record<string, Array<{count: number; value: string}>>,
    PropValuesParams
  >({
    method: 'POST',
    path: 'prop-values',
    paramsFn: params => ({
      ...tizraKebabize(params),
      'full-info': true,
    }),
    dataFn: ({data, params: {propNames}}) =>
      Array.isArray(data) ? {[propNames[0]]: data} : data,
  }),

  pageInfo: info<any, PageInfo, IdParams & {pageNum: number}>({
    path: 'reader/page-info',
  }),

  pubInfo: info<any, PubInfo, IdParams>({
    path: 'reader/pub-info',
  }),

  query: info<unknown, MetaObject[], IdParams & FieldsParams>({
    path: 'query',
    dataFn: ({data}) => tizraCamelize(ensureArray(data)) as MetaObject[],
  }),

  quickSearchFields: info<unknown, string[]>({
    path: 'quick-search-fields',
  }),

  readerToc: info<unknown, NestedTocEntry[], IdParams>({
    path: 'reader/toc',
  }),

  redeem: info<
    unknown,
    (FormErrors | Redemption) & {status: number},
    KebabXorCamelCasedProperties<{redemptionCode: string}>
  >({
    method: 'POST',
    path: 'redeem',
    isOk: ({status}) => status !== 500,
    dataFn: ({data, status}) =>
      ({...tizraCamelize(data), status}) as (FormErrors | Redemption) & {
        status: number
      },
  }),

  registerUser: info<
    FormErrors,
    FormErrors & {status: number},
    {
      FirstName: string
      LastName: string
      email: string
      password: string
      successUrl: string
    }
  >({
    method: 'POST',
    path: 'register-user',
    paramsFn: R.identity, // no kebab
    isOk: ({status}) => status !== 500,
    dataFn: ({data, status}) => ({...data, status}),
  }),

  relevantOffers: info<
    unknown,
    Offer[],
    IdParams & FieldsParams & {requiredTags?: string[]}
  >({
    path: 'query/relevant-offers',
  }),

  search: info<unknown, Paged<SearchResult>, SearchParams>({
    method: 'POST',
    path: 'search',
  }),

  searchCount: info<unknown, number, SearchQueryParams>({
    method: 'POST',
    path: 'search-count',
  }),

  searchTypes: info<
    Record<string, unknown>,
    SearchTypes,
    // Technically, the search-types API can be called without specifying
    // meta-types, which causes the server to return a default set. We always
    // want the caller to specify meta-types because there's no guarantee that
    // the server's default will contain required info.
    {metaType: string[]}
  >({
    path: 'search-types',
    dataFn: ({data}) => R.map(tizraCamelize, data) as SearchTypes,
  }),

  toc: info<unknown, TocEntry[], IdParams>({
    path: 'reader/toc',
    dataFn: ({data}) =>
      flattenReaderToc(tizraCamelize(data) as NestedTocEntry[]),
  }),

  types: info<
    unknown[] | Record<string, unknown>,
    Record<string, AdminType>,
    {namePropName?: boolean; table?: boolean}
  >({
    path: 'types',
    paramsFn: params => tizraKebabize({table: true, ...params}),
    // API changed from returning an object to a list, just change it back for
    // our use.
    dataFn: ({data}) =>
      Array.isArray(data) ?
        R.indexBy(R.prop('name'), tizraCamelize(data) as AdminType[])
      : (R.map(tizraCamelize, data) as Record<string, AdminType>),
  }),
} as const satisfies Record<string, Info>

export type Infos = typeof infos
