import {TREE} from 'tizra'
import {ValueOf} from 'type-fest'

export const weights = ['light', 'regular', 'bold', 'black'] as const

export type Weight = (typeof weights)[number]

export const scales = ['size', 'height', 'margin'] as const

export type Scale = (typeof scales)[number]

export const perFontSuffixes = [
  'ff',
  'fwl',
  'fwr',
  'fwb',
  'fwbb',
  'fss',
  'fsh',
  'fsm',
] as const

export type FontSuffix = (typeof perFontSuffixes)[number]

export const perFontCssVars = {
  fontFamily: '--eg-ff',
  weights: {
    light: '--eg-fwl',
    regular: '--eg-fwr',
    bold: '--eg-fwb',
    black: '--eg-fwbb',
  },
  scales: {
    size: '--eg-fss',
    height: '--eg-fsh',
    margin: '--eg-fsm',
  },
} as const satisfies {
  fontFamily: string
  weights: {[k in Weight]: string}
  scales: {[k in Scale]: string}
}

export type PerFontCssVar =
  | Extract<ValueOf<typeof perFontCssVars>, string>
  | ValueOf<(typeof perFontCssVars)['weights']>
  | ValueOf<(typeof perFontCssVars)['scales']>

export type FontVars = {[v in PerFontCssVar]: string}

export const cssVars = {
  ...perFontCssVars,
  colors: {
    link: '--eg-cl',
    underline: '--eg-cu',
  },
  relativeWeights: {
    bolder: '--eg-rwb',
    boldest: '--eg-rwbb',
  },
  lineHeight: '--eg-lh',
} as const

export const classes = {
  stack: `${TREE}-stack`,
  stackItem: `${TREE}-stack-item`,
  textContainer: `${TREE}-text-container`,
} as const
